import api from './api';

const API_URL = `/reports`;

class ReportService {
    getAllReports(limit, page) {
        return api.get(API_URL, {
                params: {
                    limit: limit,
                    page: page
                }
            }
        );
    }

    getAllReportsWater(limit, page) {
        return api.get(`${API_URL}-water`, {
                params: {
                    limit: limit,
                    page: page
                }
            }
        );
    }

    getReport(id) {
        return api.get(`${API_URL}/${id}`
        );
    }

    addReport(content) {
        return api.post(`${API_URL}`, content);
    }

    addReportWater(content) {
        return api.post(`${API_URL}-water`, content);
    }

    removeReport(id) {
        return api.delete(`${API_URL}/${id}`
        );
    }

    removeReportWater(id) {
        return api.delete(`${API_URL}-water/${id}`
        );
    }

    getReportDetailsList(id, limit, page,) {
        return api.get(`${API_URL}/${id}/details`, {
                params: {
                    limit: limit,
                    page: page,
                }
            }
        );
    }

    getReportDetailsListWater(id, limit, page,) {
        return api.get(`${API_URL}-water/${id}/details`, {
                params: {
                    limit: limit,
                    page: page,
                }
            }
        );
    }

    getReportDetailsShow(id, idDetails) {
        return api.get(`${API_URL}/${id}/details/${idDetails}`
        );
    }

    getReportDetailsShowWater(id, idDetails) {
        return api.get(`${API_URL}-water/${id}/details/${idDetails}`
        );
    }

    getExportReportWater(id) {
        return api.get(`${API_URL}-water/${id}/export`,{ responseType: 'blob' },
        );
    }

    getExportReport(id) {
        return api.get(`${API_URL}/${id}/export`,{ responseType: 'blob' },
        );
    }

}

export default new ReportService();
