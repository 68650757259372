<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Lista raportów</h3>
        </header>
        <DataTable :value="content" v-model:filters="filters" :loading="loading" removableSort responsiveLayout="scroll"
                   :globalFilterFields="['name', 'year', 'quarter']">
            <template #header>
                <div class="p-d-flex p-jc-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search"/>
                            <InputText v-model="filters['global'].value" placeholder="Wyszukaj"/>
                        </span>
                </div>
            </template>
            <template #empty>
                Nie znalieziono raportów
            </template>
            <template #loading>
                Ładwanie raportów. Proszę czekać
            </template>
            <Column field="createdAt" header="Data" :sortable="true"></Column>
            <Column field="year" header="Rok" :sortable="true"></Column>
            <Column field="quarter" header="Kwartał" :sortable="true"></Column>
            <!--            <Column field="place" header="Miejscowość" :sortable="true"></Column>-->
            <!--            <Column field="zipCode" header="Kod pocztowy" :sortable="true"></Column>-->
            <!--            <Column field="street" header="Ulica" :sortable="true"></Column>-->
            <!--            <Column field="buildingNumber" header="Nr domu" :sortable="true"></Column>-->
            <!--            <Column field="apartmentNumber" header="Nr mieszkania" :sortable="true"></Column>-->
            <Column headerStyle="width: 20em" bodyStyle="text-align: center; display: inline-block;">
                <template #body="slotProps">
                    <Button type="button" class="margin1" label="Pokaż"
                            v-on:click="showReort(slotProps.data.id)"></Button>
                    <Button type="button" class="p-button-help margin1" label="Export"
                            v-on:click="getExportReport(slotProps.data.id)"></Button>
                    <Button type="button" class="p-button-danger margin1" label="Usuń"
                            v-on:click="removeReport(slotProps.data.id)"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import ReportService from "../../services/reports.service";
    import {FilterMatchMode} from 'primevue/api';

    export default {
        inject: ['global'],
        name: "ReportsList",
        data() {
            return {
                content: null,
                lazyParams: {
                    limit: 100,
                    page: 1
                },
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH}
                },
                loading: true,
            };
        },
        methods: {
            showReort: function (id) {
                if (this.$route.params.type === 'woda') {
                    this.$router.push({path: `${this.global.state.instancePatch}/raporty/woda/lista/${id}/lista`});
                } else {
                    this.$router.push({path: `${this.global.state.instancePatch}/raporty/deklaracje/lista/${id}/lista`});
                }
            },
            getExportReport: function (id) {
                if (this.$route.params.type === 'woda') {
                    ReportService.getExportReportWater(id).then(
                        (response) => {
                            const headerval = response.headers['content-disposition'];
                            let filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
                            filename = decodeURI(filename);
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', filename);
                            document.body.appendChild(link);
                            link.click();
                            window.URL.revokeObjectURL(url);
                            link.remove();
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                } else {
                    ReportService.getExportReport(id).then(
                        (response) => {
                            const headerval = response.headers['content-disposition'];
                            let filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
                            filename = decodeURI(filename);
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', filename);
                            document.body.appendChild(link);
                            link.click();
                            window.URL.revokeObjectURL(url);
                            link.remove();
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            },
            removeReport: function (id) {
                if (this.$route.params.type === 'woda') {
                    ReportService.removeReportWater(id).then(
                        () => {
                            this.getList();
                            this.$toast.add({
                                severity: 'error',
                                summary: 'Usunięto',
                                detail: 'Poprawnie usunięto raport',
                                life: 3000
                            });
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                } else {
                    ReportService.removeReport(id).then(
                        () => {
                            this.getList();
                            this.$toast.add({
                                severity: 'error',
                                summary: 'Usunięto',
                                detail: 'Poprawnie usunięto raport',
                                life: 3000
                            });
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            },
            getList: function () {
                if (this.$route.params.type === 'woda') {
                    ReportService.getAllReportsWater(this.lazyParams.limit, this.lazyParams.page).then(
                        (response) => {
                            this.loading = false;
                            this.content = response.data.items;
                            this.content = this.content.map(item => ({
                                id: item.id,
                                createdAt: new Date(item.createdAt).toISOString().split('T')[0],
                                year: item.year,
                                quarter: item.quarter,
                                place: item.place,
                                zipCode: item.zipCode,
                                street: item.street,
                                buildingNumber: item.buildingNumber,
                                apartmentNumber: item.apartmentNumber
                            }));
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                } else {
                    ReportService.getAllReports(this.lazyParams.limit, this.lazyParams.page).then(
                        (response) => {
                            this.loading = false;
                            this.content = response.data.items;
                            this.content = this.content.map(item => ({
                                id: item.id,
                                createdAt: new Date(item.createdAt).toISOString().split('T')[0],
                                year: item.year,
                                quarter: item.quarter,
                                place: item.place,
                                zipCode: item.zipCode,
                                street: item.street,
                                buildingNumber: item.buildingNumber,
                                apartmentNumber: item.apartmentNumber
                            }));
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            },
        },
        mounted() {
            this.getList();
        },
    };
</script>
